<template>
  <div class="dashboard">
    <div class="header">
      <div>
        <span class="username">{{ user.name }}</span>
        <span class="dashboard-text">Dashboard</span>
      </div>
      <span class="id"
        >user <span>{{ user.id }}</span>
      </span>
    </div>
    <ul>
      <InfoBox v-for="(box, i) in boxes" :key="i" :box="box"></InfoBox>
    </ul>
    <!-- <div class="charts">
      <BarChart></BarChart>
      <PolarChart></PolarChart>
    </div> -->
  </div>
</template>

<script>
import InfoBox from "@/components/panelSections/dashboard/InfoBox.vue";
// import PolarChart from "@/components/panelSections/dashboard/PolarChart.vue";
// import BarChart from "@/components/panelSections/dashboard/BarChart.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      boxes: [
        {
          name: "Total Joined Tournaments",
          info: "...",
          icon: "trophy",
          color1: "#41e5da",
          color2: "#677fe8",
        },
        {
          name: "Total Wallets",
          info: "...",
          icon: "wallet",
          color1: "#07c733",
          color2: "#3ab2b6",
        },
        {
          name: "Total Payments",
          info: "...",
          icon: "credit-card",
          color1: "#ff5f6d",
          color2: "#d30d0d",
        },
        {
          name: "Success Payments",
          info: "...",
          icon: "trophy",
          color1: "#fcdd47",
          color2: "#ff8052",
        },
        {
          name: "Total Tickets",
          info: "...",
          icon: "trophy",
          color1: "#61045f",
          color2: "#ff41b6",
        },
        // {
        //   name: "Total Tournaments",
        //   info: "...",
        //   icon: "trophy",
        //   color1: "#bdc3c7",
        //   color2: "#2c3e50",
        // },
        // {
        //   name: "Total Tournaments",
        //   info: "...",
        //   icon: "trophy",
        //   color1: "#cc2b5e",
        //   color2: "#753a88",
        // },
        // {
        //   name: "Total Tournaments",
        //   info: "...",
        //   icon: "trophy",
        //   color1: "#cc2b5e",
        //   color2: "#753a88",
        // },
      ],
      isLoading: false,
    };
  },
  components: {
    InfoBox,
    // BarChart,
    // PolarChart,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.fetchDashboardData();
  },
  methods: {
    ...mapActions(["getMainReports"]),
    fetchDashboardData() {
      this.isLoading = true;
      this.getMainReports()
        .then((res) => {
          
          this.boxes[0].info = res.data.result.all_joined_tournament;
          this.boxes[1].info = res.data.result.wallets;
          this.boxes[2].info = res.data.result.all_payments;
          this.boxes[3].info = res.data.result.all_success_payments;
          this.boxes[4].info = res.data.result.ticket_counts;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.dashboard {
  min-height: 70vh;

  @include md {
    font-size: 12px;
  }
  @include sm {
    font-size: 10px;
  }
  .header {
    margin: 1.25em 0.9375em;
    display: flex;
    justify-content: space-between;
    align-items: end;
    border-bottom: 0.125em solid var(--color7);
    .username {
      color: var(--color7);
      font-size: 1.5em;
      margin-right: 0.1875em;
      font-weight: 900;
    }
    .dashboard-text {
      font-size: 1.5em;
      color: var(--color5);
    }
    .id {
      font-size: 1em;
      color: var(--color7);
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  .charts {
    // display: flex;
  }
}
</style>
